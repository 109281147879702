import {Injectable} from '@angular/core';

@Injectable()
export class MenuStateService {

    opened: boolean;

    isOpen() {
        return this.opened;
    }

    isClosed() {
        return !this.opened;
    }

    setIsOpen(isOpen: boolean) {
        this.opened = isOpen;
    }
}
