import {Route, UrlMatchResult, UrlSegment, UrlSegmentGroup} from '@angular/router';

function match(paramName: string, segments: UrlSegment[]) {
    const posParams = {};
    posParams[paramName] = segments[0];

    if (/^\d+$/.exec(segments[0].path)) {
        return {
            consumed: [segments[0]],
            posParams: posParams
        };
    }
    return null;
}

export function poiIdMatcher(segments: UrlSegment[], segmentGroup: UrlSegmentGroup, route: Route): UrlMatchResult | null {
    return match('poiId', segments);
}

export function areaIdMatcher(segments: UrlSegment[], segmentGroup: UrlSegmentGroup, route: Route): UrlMatchResult | null {
    return match('areaId', segments);
}
