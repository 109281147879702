export class POIContent {
    type: string;
    audioPath?: string;
    slides?: Array<{content: string}>;
    imageHTML?: string;
    videoHTML?: string;
    title?: string;

    constructor(props?) {
        if (props) {
            Object.keys(props).forEach(propName => {
                this[propName] = props[propName];
            });
        }
    }
}
