import {Component, OnInit} from '@angular/core';
import {AreaService} from '../../service/AreaService';
import {ActivatedRoute, Router} from '@angular/router';
import {MenuPageService} from '../../service/MenuPageService';
import {GeoObject} from '../../model/GeoObject';
import {AppPageContentPipe} from '../../pipes/AppPageContentPipe';
import {Area} from '../../model/Area';
import {LocaleService} from '../../service/LocaleService';
import {AreaContent} from '../../model/AreaContent';
import * as L from 'leaflet';
import {MenuStateService} from '../../service/MenuStateService';

@Component({
    selector: 'app-area-map',
    templateUrl: 'poi-map.html',
    styleUrls: ['poi-map.scss'],
})
export class PoiMapComponent implements OnInit {

    map: L.Map;

    areaTitle: string;

    constructor(
        private areaService: AreaService,
        private route: ActivatedRoute,
        private router: Router,
        private menuPageService: MenuPageService,
        private appPageContentPipe: AppPageContentPipe,
        private localeService: LocaleService,
        public menuStateService: MenuStateService
    ) {}

    ngOnInit(): void {

        setTimeout(() => {
            this.route.params.subscribe(params => {
                this.areaService.loaded().subscribe(loaded => {
                    if (!loaded) {
                        return;
                    }
                    if (this.map) {
                        this.showLastPOI();
                    } else {
                        this.initializeMap(params.areaId);
                    }
                });
            });
        }, 200);
    }

    initializeMap(areaId: number) {
        this.map = new L.Map('poiMap').setView([50.977994, 9.830360], 7);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {}).addTo(this.map);

        const area = this.areaService.findById(areaId);
        this.menuPageService.areaDetails(area);
        this.areaTitle = area.title;
        this.addGeoobjectsToMap(area, { inactive: false });
        this.addGeogroupsToMap(area);

        setTimeout(this.showLastPOI.bind(this), 500);
    }

    showLastPOI() {
        const poiJSON = localStorage.getItem('lastPOI');
        if (poiJSON) {
            localStorage.removeItem('lastPOI');
            const poi = JSON.parse(poiJSON);
            if (poi.geoJSON && poi.geoJSON.features && poi.geoJSON.features[0].geometry && poi.geoJSON.features[0].geometry.coordinates) {
                const coords = poi.geoJSON.features[0].geometry.coordinates;
                this.map.setView(
                    [coords[1], coords[0]],
                    16
                );
            }
        }
    }

    getPopup(params) {

        const domelem = document.createElement('div');

        domelem.innerHTML = '';

        if (params.markerHtml) {
            domelem.innerHTML += '<div class="map-icon-wrapper">' + params.markerHtml + '</div>';
        }

        if (params.title) {
            domelem.innerHTML += '<h1>' + params.title + '</h1>';
        }

        if (params.contentHtml) {
            domelem.innerHTML += params.contentHtml;
        }

        if (params.longDescPage) {
            domelem.innerHTML += '<a class="nnn-icon-right map-popup-arrow"></a>';
        }

        domelem.setAttribute('data-geoobject-id', params.geoobjectId);
        domelem.className = 'clearfix';

        return domelem;
    }

    /**
     * Get group popup
     *
     * Gets group popup and returns it.
     *
     * @params  params
     * @return  DOM element
     */

    getGroupPopup(params) {

        const domelem = document.createElement('div');

        domelem.innerHTML = '';

        if (params.title) {
            domelem.innerHTML += '<h1>' + params.title + '</h1>';
        }

        if (params.contentHtml) {
            domelem.innerHTML += params.contentHtml;
        }

        if (params.longDescPage) {
            domelem.innerHTML += '<a class="nnn-icon-right map-popup-arrow"></a>';
        }

        domelem.setAttribute('data-geogroup-id', params.geogroupId);
        domelem.className = 'clearfix';

        return domelem;
    }

    /**
     * Get popup options
     *
     * Gets popup options and returns it.
     *
     * @return  obj
     */

    getPopupOptions(mapIcon) {

        const popupOptions: any = {
            maxWidth: 305,
            minWidth: 305,
            className: 'area-geoobject-popup',
            closeButton: false,
            autoPan: true,
            keepInView: false
        };

        if (mapIcon) {
            popupOptions.offset = L.point(-8, -25);
        }

        return popupOptions;
    }

    /**
     * Get group popup options
     *
     * Gets group popup options and returns it.
     *
     * @return  obj
     */

    getGroupPopupOptions() {

        return {
            maxWidth: 305,
            minWidth: 305,
            className: 'area-geogroup-popup',
            closeButton: false,
            keepInView: true
        };
    }

    getPositionOnRoute(areaContent: AreaContent, geobject: GeoObject) {

        const geogroups = areaContent.geogroups;
        let result = null;
        const geoobjectId = geobject.id;

        if (geogroups.length !== 0) {
             geogroups.forEach((geogroup: GeoObject) => {
                const geoObjects = geogroup.geobjects;
                if (geoObjects && geoObjects.find(gId => gId === geoobjectId)) {
                    result = {
                        routeId: geogroup.id,
                        position: geoObjects.findIndex(gId => gId === geoobjectId) + 1
                    };
                }
            });
        }

        return result;
    }

    getMarker(params): L.Marker {

        const that = this;
        return L.geoJSON( params.geoJson, {

            style: feature => {
                return that.getMarkerOptions(params.options);
            },

            onEachFeature: (feature, layer) => {

                if (params.popup) {
                    layer.bindPopup(params.popup, params.popupOptions);
                }

            },

            pointToLayer: (feature, latlng) => {
                return that.getDivIcon(params, latlng);
            }

        });
    }

    /**
     * Get group marker
     *
     * Get group marker and return it.
     *
     * @params  geometry
     * @params  options         options for the marker
     * @params  popup           popup element for the marker
     * @params  popupOptions    popup options for the marker
     * @return  GeoJSON
     */

    getGroupMarker(params) {

        const that = this;

        const geoJsonObject = L.geoJSON( params.geoJson, {

            style: feature => {
                return that.getGroupMarkerOptions(params.options);
            },

            onEachFeature: (feature, layer) => {

                if (params.popup) {
                    layer.bindPopup(params.popup, params.popupOptions);
                }

            }

        });

        return geoJsonObject;
    }

    getMarkerOptions(options) {

        const defaultOptions: any = {
            color: '#FEFE53',
            fillOpacity: 0.2,
            weight: 3
        };

        if (options.color) {
            defaultOptions.color = options.color;
            defaultOptions.fillColor = options.fillColor;
        }

        if (options.fillColor) {
            defaultOptions.fill = true;
            defaultOptions.fillColor = options.fillColor;
        }

        return defaultOptions;
    }

    /**
     * Get group marker options
     *
     * Gets group marker options and return it.
     *
     * @params  options         options to merge
     * @return  obj
     */

    getGroupMarkerOptions(options) {

        const defaultOptions = {
            color: '#FEFE53',
            fillOpacity: 0.2,
            weight: 3
        };

        if (options.color) {
            defaultOptions.color = options.color;
        }

        return defaultOptions;
    }

    /**
     * Get div icon
     *
     * Gets div icon and returns it.
     *
     * @return  obj
     */

    getDivIcon(params, latlng) {

        const divIconOptions = {
            className: 'my-div-icon',
            html: params.markerHtml,
            iconAnchor: [26, 35],
            iconSize: [60, 62]
        };

        if (params.title) {

            if (params.position) {
                divIconOptions.html += '<div class="map-icon-title hidden">' + params.position + ' - ' + params.title + '</div>';
            } else {
                divIconOptions.html += '<div class="map-icon-title hidden">' + params.title + '</div>';
            }
        }

        const icon = L.divIcon(divIconOptions);

        const markerOptions = {
            icon,
            opacity: 1
        };

        if (params.inactive) {
            markerOptions.opacity = 0.5;
        }

        return L.marker(latlng, markerOptions);
    }


    addGeoobjectsToMap(area: Area, params) {
        this.areaService.findContentForArea(area.idareas).subscribe(areaContent => {
            const geoobjects = areaContent.geoobjects;
            const cluster = L.featureGroup();
            Object
                .values(geoobjects)
                .forEach((geoobject: GeoObject) => {
                    const geoJson = geoobject.geoJSON;
                    if (geoJson) {

                        let longDescPage = null;
                        if (geoobject.long_desc_page_id !== null) {
                            longDescPage = this.areaService.findPageById(geoobject.long_desc_page_id);
                        }
                        let shortDescPage = null;
                        if (geoobject.short_desc_page_id !== null) {
                            shortDescPage = areaContent.pages[geoobject.short_desc_page_id];
                        }

                        const geoobjectId = geoobject.id;
                        const geoobjectTitle = geoobject.title;
                        const markerHtml = geoobject.map_icon;
                        let popup;

                        const popupParams: any = {
                            geoobjectId
                        };

                        if (geoobjectTitle) {
                            popupParams.title = geoobjectTitle;
                        }

                        if (markerHtml) {
                            popupParams.markerHtml = markerHtml;
                        }

                        if (shortDescPage) {
                            popupParams.contentHtml = this.appPageContentPipe.transform(
                                shortDescPage.content,
                                'areas/' + this.localeService.get() + '/' + area.idareas);
                        }
                        if (geoobject.long_desc_page_id !== null) {

                            if (longDescPage) {
                                popupParams.longDescPage = longDescPage;
                            }

                        }
                        // Get Popup
                        popup = this.getPopup(popupParams);

                        if (geoobject.long_desc_page_id !== null) {
                            popup.onclick = () => {
                                this.router.navigate(['area/' + area.idareas + '/poi/' + geoobject.long_desc_page_id]);
                            };
                        }

                        const markerOptions: any = {};

                        if (geoobject.map_border_color) {
                            markerOptions.color = geoobject.map_border_color;
                        }

                        if (geoobject.map_area_color) {
                            markerOptions.fillColor = geoobject.map_area_color;
                        }

                        const markerParams: any = {
                            geoJson,
                            options: markerOptions,
                            markerHtml
                        };

                        if (popup) {
                            markerParams.popup = popup;
                            markerParams.popupOptions = this.getPopupOptions(markerHtml);
                        }

                        if (params.inactive) {
                            markerParams.inactive = true;
                        }

                        if (geoobjectTitle) {
                            markerParams.title = geoobjectTitle;

                            const positionObj = this.getPositionOnRoute(areaContent, geoobject);

                            if (positionObj) {
                                markerParams.position = positionObj.position;
                            }
                        }

                        const geoJsonObject = this.getMarker(markerParams);
                        cluster.addLayer(geoJsonObject);
                    }
                });

            this.map.addLayer(cluster);
            this.map.fitBounds(cluster.getBounds());

            this.map.on('zoomend', e => {
                if (this.map.getZoom() > 15) {
                    document.querySelectorAll('.map-icon-title').forEach(node => {
                        node.classList.remove('hidden');
                    });
                } else {
                    document.querySelectorAll('.map-icon-title').forEach(node => {
                        node.classList.add('hidden');
                    });
                }
            });
        });

    }

    addGeogroupsToMap(area: Area) {

        this.areaService.findContentForArea(area.idareas).subscribe(content => {
            content.geogroups.forEach((geogroup: GeoObject) => {

                const shortDescPage = this.areaService.findPageById(geogroup.short_desc_page_id);
                const longDescPage = this.areaService.findPageById(geogroup.long_desc_page_id);

                let popupOptions;
                let popup;

                if (shortDescPage) {

                    // Get Popup
                    popup = this.getGroupPopup({
                        geogroupId: geogroup.id,
                        title: shortDescPage.title,
                        contentHtml: this.appPageContentPipe.transform(
                            shortDescPage.content,
                            'areas/' + this.localeService.get() + '/' + area.idareas),
                        longDescPage
                    });

                    if (longDescPage) {

                        popup.onclick = () => {
                            this.router.navigate(['area/' + area.idareas + '/poi/' + geogroup.id]);
                        };
                    }

                    popupOptions = this.getGroupPopupOptions();
                }

                const markerOptions: any = {};

                if (geogroup.map_border_color) {
                    markerOptions.color = geogroup.map_border_color;
                }

                const markerParams: any = {
                    geoJson: geogroup.geoJSON,
                    options: null
                };

                if (geogroup.map_border_color) {

                    markerParams.options = {
                        color: geogroup.map_border_color
                    };
                }

                if (popup) {
                    markerParams.popup = popup;
                    markerParams.popupOptions = popupOptions;
                }

                const geoJsonObject = this.getGroupMarker(markerParams);
                geoJsonObject.addTo(this.map);

            });
        });
    }
}
