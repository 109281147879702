export class GeoObject {

    category: string;
    geoJSON: any;
    id: number;
    intervention: {radius: number};
    long_desc_page_id: number;
    map_icon: string;
    nfc_id: number;
    qrcodeid: number;
    short_desc_page_id: number;
    tags: Array<any>;
    title: string;
    infocenter?: any;
    map_border_color?: any;
    map_area_color?: any;
    geobjects?: any;

    constructor(props?) {
        if (props) {
            Object.keys(props).forEach(propName => {
                this[propName] = props[propName];
            });
        }
    }

    static fromJSON(json): GeoObject {
        return new GeoObject({
            category: json.category,
            geoJSON: json.geoJSON,
            id: json.id,
            intervention: json.intervention,
            long_desc_page_id: json.long_desc_page_id,
            map_icon: json.map_icon,
            nfc_id: json.nfc_id,
            qrcodeid: json.qrcodeid,
            short_desc_page_id: json.short_desc_page_id,
            tags: json.tags,
            title: json.title,
            map_border_color: json.map_border_color,
            map_area_color: json.map_area_color,
            geoObjects: json.geoObjects
        });
    }
}
