export class Page {
    content: string;
    id: number;
    title: string;

    constructor(props?) {
        if (props) {
            Object.keys(props).forEach(propName => {
                this[propName] = props[propName];
            });
        }
    }

    static fromJSON(json): Page {
        return new Page({
            content: json.content,
            id: json.id,
            title: json.title
        });
    }
}
