import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {Area} from '../model/Area';
import {AreaService} from './AreaService';

@Injectable()
export class MenuPageService {

    public menuPages: BehaviorSubject<Array<{}>>;

    private mainMenuPages = [
        {
            title: 'area.map.title',
            url: '/area/map',
        },
        {
            title: 'area.list.title',
            url: '/area/list',
        }
    ];

    constructor(
        private router: Router,
        private areaService: AreaService
    ) {
        this.menuPages = new BehaviorSubject<Array<{}>>(this.mainMenuPages);
    }

    public reset() {
        this.menuPages.next(this.mainMenuPages);
    }

    private areaDetailsMenuPages(area: Area) {
        const pages = [
            {
                title: 'back',
                url: '/area/',
            },
            {
                title: 'poi.map.title',
                url: '/area/' + area.idareas + '/poi/map',
            },
            {
                title: 'poi.list.title',
                url: '/area/' + area.idareas + '/poi/list',
            }
        ];

        this.areaService.findContentForArea(area.idareas).subscribe(areaContent => {
            if (
                areaContent.meta
                && areaContent.meta.infocenter
                && areaContent.meta.infocenter.pois
                && areaContent.meta.infocenter.pois.length > 0
            ) {
                pages.push({
                    title: 'poi.infocenter.title',
                    url: '/area/' + area.idareas + '/infocenter'
                });
            }

            this.menuPages.next(pages);
        });
    }

    areaDetails(area: Area) {
        this.areaDetailsMenuPages(area);
    }

    isActivePage(page: {url: string}) {
        return this.router.url === page.url;
    }
}
