import {AreaContentMetaInfocenter} from './AreaContentMetaInfocenter';

export class AreaContentMetaInformation {

    idareas: number;
    infocenter: AreaContentMetaInfocenter;
    languge_tag: string;
    mbtilefile: string;
    menue_elements: Array<any>;
    newsfeed_url: string;
    splash_page_id: number;
    title: string;
    version: string;

    constructor(props?) {
        if (props) {
            Object.keys(props).forEach(propName => {
                this[propName] = props[propName];
            });
        }
    }

    static fromJSON(json): AreaContentMetaInformation {
        return new AreaContentMetaInformation({
            idareas: json.idareas,
            infocenter: json.infocenter !== null ? AreaContentMetaInfocenter.fromJSON(json.infocenter) : null,
            languge_tag: json.languge_tag,
            mbtilefile: json.mbtilefile,
            menue_elements: json.menue_elements,
            newsfeed_url: json.newsfeed_url,
            splash_page_id: json.splash_page_id,
            title: json.title,
            version: json.version
        });
    }
}
