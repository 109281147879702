import {GeoObject} from './GeoObject';
import {Page} from './Page';
import {AreaContentMetaInformation} from './AreaContentMetaInformation';

export class AreaContent {
    meta: AreaContentMetaInformation;
    geoobjects: { [key: number]: GeoObject};
    categories: Array<string>;
    geogroups: Array<GeoObject>;
    pages: { [key: number]: Page };
    assetList: Array<string>;

    constructor(props?) {
        if (props) {
            Object.keys(props).forEach(propName => {
                this[propName] = props[propName];
            });
        }
    }

    public static fromJSON(json): AreaContent {
        const ac = new AreaContent({
            id: json.idareas,
            meta: json.meta != null ? AreaContentMetaInformation.fromJSON(json.meta) : null,
            geogroups: json.geogroups != null ? json.geogroups.map(GeoObject.fromJSON) : null,
            categories: json.categories,
            pages: json.pages != null ? json.pages.map(Page.fromJSON) : null,
            assetList: json.assetList
        });

        ac.geoobjects = {};
        json.geoobjects.forEach(goData => {
            const go = GeoObject.fromJSON(goData);
            ac.geoobjects[go.id] = go;
        });

        ac.pages = {};
        json.pages.forEach(pageData => {
            const p = Page.fromJSON(pageData);
            ac.pages[p.id] = p;
        });

        return ac;
    }

    findPageById(pageId: number) {
        return this.pages[pageId];
    }
}
