export class Area {

    idareas: number;
    debug: boolean;
    geoJSON: { type: string, crs: any, features: Array<any>};
    long_desc_page_id: number;
    packages_available: {de: any, en: any};
    short_desc_page_id: number;
    state: Array<string>;
    title: string;
    map_border_color?: any;
    map_area_color?: any;
    center: any;

    constructor(props?) {
        if (props) {
            Object.keys(props).forEach(propName => {
                this[propName] = props[propName];
            });
        }
    }

    static fromJSON(json): Area {
        return new Area({
            idareas: json.idareas,
            debug: json.debug,
            geoJSON: json.geoJSON,
            long_desc_page_id: json.long_desc_page_id,
            packages_available: json.packages_available,
            short_desc_page_id: json.short_desc_page_id,
            state: json.state,
            title: json.title,
        });
    }

    public clone(): Area {
        return new Area({
            idareas: this.idareas,
            debug: this.debug,
            geoJSON: this.geoJSON,
            long_desc_page_id: this.long_desc_page_id,
            packages_available: this.packages_available,
            short_desc_page_id: this.short_desc_page_id,
            state: this.state,
            title: this.title,
        });
    }
}
