import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LocaleService} from './LocaleService';
import {Area} from '../model/Area';
import {AreaContent} from '../model/AreaContent';
import {BehaviorSubject, Observable, of} from 'rxjs';
import { map } from 'rxjs/operators';
import {Page} from '../model/Page';

@Injectable()
export class AreaService {

    private areaData: { [key: number]: Area } = {};
    private areaContents: { [key: number]: AreaContent } = {};
    private pageData: { [key: number]: Page } = {};

    private isLoaded: BehaviorSubject<boolean>;

    constructor(
        private http: HttpClient,
        private localeService: LocaleService
    ) {
        this.isLoaded = new BehaviorSubject<boolean>(false);
        this.loadData();
    }

    loaded(): BehaviorSubject<boolean> {
        return this.isLoaded;
    }

    loadData() {
        this.http.get('assets/app/' + this.localeService.get() + '/db.json').subscribe((data: any) => {
            if (data != null) {
                Object.values(data.areas).map(areaData => {
                    const area = Area.fromJSON(areaData);
                    this.areaData[area.idareas] = area;
                });

                Object.values(data.pages).map(pageData => {
                    const page = Page.fromJSON(pageData);
                    this.pageData[page.id] = page;
                });

                this.isLoaded.next(true);
            } else {
                console.log('no data found');
            }
        });
    }

    findById(id: number): Area {
        return this.areaData[id];
    }

    findAll(): Array<Area> {
        return Object.values(this.areaData)
            .filter(a => a.debug === false)
            .map(a => a.clone())
            .sort((a, b) => {
                if (a.title < b.title) {
                    return -1;
                }
                if (a.title > b.title) {
                    return 1;
                }
                return 0;
            });
    }

    findContentForArea(areaId: number): Observable<AreaContent> {
        if (this.areaContents[areaId] === undefined) {
            return this.requestAreaContent(areaId);
        }

        return of(this.areaContents[areaId]);
    }

    private requestAreaContent(areaId: number): Observable<AreaContent> {
        return this.http.get('assets/areas/' + this.localeService.get() + '/' + areaId + '/db.json').pipe(
            map((data: any) => {
                let ac = null;
                if (data != null) {
                    ac = AreaContent.fromJSON(data);
                } else {
                    console.log('no data found');
                }
                return ac;
            })
        );
    }

    findPageById(pageId: number) {
        return this.pageData[pageId];
    }
}
