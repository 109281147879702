import { NgModule } from '@angular/core';
import {PreloadAllModules, Route, RouterModule, Routes, UrlMatchResult, UrlSegment, UrlSegmentGroup} from '@angular/router';
import {AreaMapComponent} from './components/area-map/area-map.component';
import {AreaListComponent} from './components/area-list/area-list.component';
import {PoiDetailsComponent} from './components/poi-details/poi-details.component';
import {PoiListComponent} from './components/poi-list/poi-list.component';
import {PoiMapComponent} from './components/poi-map/poi-map.component';
import {NotFoundComponent} from './components/not-found.component/not-found.component';
import {areaIdMatcher, poiIdMatcher} from './matcher/UrlMatcher';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'area',
    pathMatch: 'full'
  },
  {
    path: 'area',
    children: [
      {
        path: '',
        redirectTo: 'map',
        pathMatch: 'full'
      },
      {
        path: 'map',
        component: AreaMapComponent
      },
      {
        path: 'list',
        component: AreaListComponent
      },
      {
        matcher: areaIdMatcher,
        children: [
          {
            path: '',
            redirectTo: 'poi/map',
            pathMatch: 'full'
          },
          {
            path: 'infocenter',
            component: PoiListComponent,
            data: { mode: 'infocenter' }
          },
          {
            path: 'poi',
            children: [
              {
                path: '',
                redirectTo: 'map',
                pathMatch: 'full'
              },
              {
                path: 'map',
                component: PoiMapComponent
              },
              {
                path: 'list',
                component: PoiListComponent,
                data: { mode: 'geoobjects' }
              },
              {
                matcher: poiIdMatcher,
                data: { state: 'poi-details'},
                component: PoiDetailsComponent
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'poi',
    children: [
      {
        matcher: poiIdMatcher,
        data: { state: 'poi-details'},
        component: PoiDetailsComponent
      }
    ]
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
