import {Component, OnInit} from '@angular/core';
import {AreaService} from '../../service/AreaService';
import {AreaContent} from '../../model/AreaContent';
import {BehaviorSubject} from 'rxjs';
import {Area} from '../../model/Area';
import {LocaleService} from '../../service/LocaleService';
import {AppPageContentPipe} from '../../pipes/AppPageContentPipe';
import {ActivatedRoute} from '@angular/router';
import {MenuPageService} from '../../service/MenuPageService';
import {MenuStateService} from '../../service/MenuStateService';

@Component({
  selector: 'app-area-list',
  templateUrl: 'area-details.html',
  styleUrls: ['area-details.scss'],
})
export class AreaDetailsComponent implements OnInit {

  area: Area;
  areaContent: BehaviorSubject<AreaContent>;
  locale: string;
  loading = true;
  shortDescription: string;
  shortDescriptionTitle: string;

  constructor(
      private areaService: AreaService,
      private localeService: LocaleService,
      private appPageContentPipe: AppPageContentPipe,
      private route: ActivatedRoute,
      private menuPageService: MenuPageService,
      public menuStateService: MenuStateService
  ) {
    this.areaContent = new BehaviorSubject<AreaContent>(null);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.route.params.subscribe(p => {
        this.areaService.loaded().subscribe(loaded => {
          if (!loaded) {
            return;
          }

          this.area = this.areaService.findById(p.areaId);
          this.menuPageService.areaDetails(this.area);

          this.areaService.findContentForArea(this.area.idareas).subscribe((content: AreaContent) => {
            this.areaContent.next(content);
          });
          this.locale = this.localeService.get();

          const shortDescription = this.areaService.findPageById(this.area.long_desc_page_id);
          this.shortDescription = this.appPageContentPipe.transform(shortDescription.content, 'app/' + this.locale);
          this.shortDescriptionTitle = this.appPageContentPipe.transform(shortDescription.title, 'app/' + this.locale);

          this.loading = false;
        });
      });
    }, 500);
  }

}
