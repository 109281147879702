import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AreaService} from '../../service/AreaService';
import {ActivatedRoute} from '@angular/router';
import {MenuPageService} from '../../service/MenuPageService';
import {GeoObject} from '../../model/GeoObject';
import {MenuStateService} from '../../service/MenuStateService';
import { elementEventFullName } from '@angular/core/src/view';

@Component({
  selector: 'app-area-list',
  templateUrl: 'poi-list.html',
  styleUrls: ['poi-list.scss'],
})
export class PoiListComponent implements OnInit {

  mode: 'geoobjects'|'infocenter';

  pois: BehaviorSubject<Array<GeoObject>>;

  constructor(
      private areaService: AreaService,
      private route: ActivatedRoute,
      private menuPageService: MenuPageService,
      public menuStateService: MenuStateService
  ) {
    this.pois = new BehaviorSubject<Array<GeoObject>>(null);
  }

  ngOnInit(): void {
    setTimeout(() => {

      this.route.params.subscribe(params => {

        this.areaService.loaded().subscribe(loaded => {
          if (!loaded) {
            return;
          }

          this.route.data.subscribe((data: {mode: 'geoobjects'|'infocenter'}) => {
            this.mode = data.mode;

            this.menuPageService.areaDetails(this.areaService.findById(params.areaId));

            this.areaService.findContentForArea(params.areaId).subscribe(content => {
              let elements;
              if (this.mode === 'infocenter') {
                if (content.meta.infocenter.pois.length > 0) {
                  elements = content.meta.infocenter.pois;
                  elements.forEach(element => {
                    element.short_page = {content: ''};
                  });
                } else {
                  elements = [];
                }
              } else if (this.mode === 'geoobjects') {
                elements = Object.values(content.geoobjects);
                console.log(content.pages);
                // let contentpages = content.pages;
                elements.forEach(element => {
                  if(element.short_desc_page_id !== null) {
                    element.short_page = content.pages[element.short_desc_page_id];
                    element.short_page.content = String(element.short_page.content).replace( /(<([^>]+)>)/ig, '');
                    console.log(element);
                  } else {
                    element.short_page = {content: ''};
                  }
                });
              }

              this.pois.next(elements.filter(c => c.long_desc_page_id !== null).sort((a, b) => {
                if (a.title < b.title) {
                  return -1;
                }
                if (a.title > b.title) {
                  return 1;
                }
                return 0;
              }));
            });
          });
        });

      });
    }, 500);
  }

}
