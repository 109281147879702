import {Component, OnInit} from '@angular/core';

import {Platform} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {MenuPageService} from './service/MenuPageService';
import {BehaviorSubject} from 'rxjs';
import {MenuStateService} from './service/MenuStateService';

declare var SmartBanner: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {

  public appPages: BehaviorSubject<Array<any>>;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private menuPageService: MenuPageService,
    private menuStateService: MenuStateService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.menuPageService.reset();
      this.appPages = this.menuPageService.menuPages;
    }, 10);

    setTimeout(() => {
      const smartBanner = new SmartBanner({
        daysHidden: 1,   // days to hide banner after close button is clicked (defaults to 15)
        daysReminder: 90, // days to hide banner after "VIEW" button is clicked (defaults to 90)
        appStoreLanguage: 'en', // language code for the App Store (defaults to user's browser language)
        title: 'Naturerbe',
        author: 'DBU Naturerbe GmbH',
        button: 'Ansehen',
        store: {
          ios: 'App Store',
          android: 'Google Play',
          windows: 'Windows store'
        },
        price: {
          ios: 'Kostenlos',
          android: 'Kostenlos',
          windows: 'Kostenlos'
        }
        // , theme: '' // put platform type ('ios', 'android', etc.) here to force single theme on all device
        , icon: 'assets/icon/naturerbe.jpg'
        // , force: 'ios' // Uncomment for platform emulation
      });

      const bannerElement = document.getElementsByClassName('smartbanner')[0] as HTMLElement;
      if (bannerElement) {
        bannerElement.style.marginTop = String(document.getElementsByTagName('ion-header')[1].offsetHeight) + 'px';
        bannerElement.style.visibility = 'visible';
      }
    }, 750);
  }

  isActivePage(page: {url: string}) {
    return this.menuPageService.isActivePage(page);
  }

  onMenuOpened() {
    this.menuStateService.setIsOpen(true);
  }

  onMenuClosed() {
    this.menuStateService.setIsOpen(false);
  }
}
