import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'appPageContent'
})
export class AppPageContentPipe implements PipeTransform {

    transform(value: string, assetPath: string): string {
        return value.replace(/{{PATH}}/gi, 'assets/' + assetPath);
    }

}
