export class AreaContentMetaInfocenter {

    menue_elements: Array<any>;
    pois: Array<any>;
    splash_page_id: number;
    title: string;

    constructor(props?) {
        if (props) {
            Object.keys(props).forEach(propName => {
                this[propName] = props[propName];
            });
        }
    }

    static fromJSON(json): AreaContentMetaInfocenter {
        return new AreaContentMetaInfocenter({
            menue_elements: json.menue_elements,
            pois: json.pois,
            splash_page_id: json.splash_page_id,
            title: json.title
        });
    }
}
