import {TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';

@Injectable()
export class LocaleService {

    constructor(
        private translateService: TranslateService
    ) {
        let language = this.translateService.getBrowserLang();
        if (['de', 'en'].indexOf(language) < 0) {
            language = 'de';
        }
        this.translateService.setDefaultLang(language);
    }

    get(): string {
        return this.translateService.defaultLang;
    }
}
