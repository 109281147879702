import {Component, OnInit} from '@angular/core';
import {AreaService} from '../../service/AreaService';
import {Area} from '../../model/Area';
import {MenuPageService} from '../../service/MenuPageService';
import {AppPageContentPipe} from '../../pipes/AppPageContentPipe';
import {LocaleService} from '../../service/LocaleService';
import {Router} from '@angular/router';
import * as L from 'leaflet';
import {MenuStateService} from '../../service/MenuStateService';

@Component({
  selector: 'app-area-map',
  templateUrl: 'area-map.html',
  styleUrls: ['area-map.scss'],
})
export class AreaMapComponent implements OnInit {

  map: L.Map;

  constructor(
      private areaService: AreaService,
      private menuPageService: MenuPageService,
      private appPageContentPipe: AppPageContentPipe,
      private localeService: LocaleService,
      private router: Router,
      public menuStateService: MenuStateService
  ) {}

  ngOnInit() {

    this.menuPageService.reset();
    setTimeout(() => {
      this.map = new L.Map('areaMap').setView([50.977994, 9.830360], 6.5);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {}).addTo(this.map);

      this.areaService.loaded().subscribe(loaded => {
        if (!loaded) {
          return;
        }
        this.initMap();
      });
    }, 500);
  }

  initMap() {
    // Loop through all areas
    this.addAreaAsCircleToMap(this.areaService.findAll());
  }

  getDivCircleMarker(params) {

    return L.marker(L.latLng(params.center.latitude, params.center.longitude), {

      icon: L.divIcon({
        className: 'area-circle-marker',
        html: '<div class="area-circle"></div><div class="map-icon-title">' + params.title + '</div>',
        iconAnchor: [18, 18],
        iconSize: [36, 36]
      })

    });
  }

  addAreaAsCircleToMap(areas: Array<Area>) {

      const cluster = L.featureGroup();

      areas.forEach(area => {

        const center = this.getCenter(area);

        if (center && center.latitude && center.longitude) {

          const shortDescPage = this.areaService.findPageById(area.short_desc_page_id);

          const markerOptions = {
            center: center,
            radius: 18,
            title: shortDescPage.title
          };

          // Get circle marker
          const circleMarker = this.getDivCircleMarker(markerOptions);

          // Get Popup
          const popup = this.getPopup({
            areaId: area.idareas,
            title: shortDescPage.title,
            contentHtml: this.appPageContentPipe.transform(shortDescPage.content, 'app/' + this.localeService.get())
          });

          popup.onclick = () => {
            this.router.navigate(['area/' + area.idareas]);
          };

          // Get popup options
          const popupOption = this.getPopupOptions();

          // Bind popup on marker
          circleMarker.bindPopup(popup, popupOption);

          // circleMarker.addTo(map);
          cluster.addLayer(circleMarker);
        }

      });

      this.map.addLayer(cluster);
      const bounds = cluster.getBounds();
      this.map.fitBounds(cluster.getBounds());

    }

  addAreaToMap(area: Area) {
    // Get short desc page
    const page = this.areaService.findPageById(area.short_desc_page_id);

    // Get geoJson
    const geoJson = area.geoJSON;
    const features = geoJson.features;

    // Loop through all features
    features.forEach(featureElement => {

      let geometries = [featureElement];

      if (featureElement.geometry.type === 'GeometryCollection') {
        geometries = featureElement.geometry.geometries;
      }

      geometries.forEach(geometry => {

        // Get Popup
        const popup = this.getPopup({
          areaId: area.idareas,
          title: page.title,
          contentHtml: this.appPageContentPipe.transform(
              page.content,
              'areas/' + this.localeService.get() + '/' + area.idareas
          )
        });
        popup.onclick = () => {
          this.router.navigate(['/area/' + area.idareas]);
        };

        const markerOptions: any = {};

        if (area.map_border_color) {
          markerOptions.color = area.map_border_color;
        }

        if (area.map_area_color) {
          markerOptions.fillColor = area.map_area_color;
        }

        const popupOptions = this.getPopupOptions();

        let mapObject = null;
        if (geometry.geometry && geometry.geometry.type === 'Point') {

          mapObject = this.getCircleMarker({
            radius: 10,
            center: {
              latitude: geometry.geometry.coordinates[1],
              longitude: geometry.geometry.coordinates[0]
            }
          });

          mapObject.bindPopup(popup, popupOptions);

        } else if (geometry.type && geometry.type === 'Point') {

          mapObject = this.getCircleMarker({
            radius: 10,
            center: {
              latitude: geometry.coordinates[1],
              longitude: geometry.coordinates[0]
            }
          });
          mapObject.bindPopup(popup, popupOptions);

        } else {
          mapObject = this.getPolygonMarker(geometry, markerOptions, popup, popupOptions);
        }

        mapObject.addTo(this.map);
      });
    });
  }

  getPopup(params) {
    const domelem = document.createElement('div');

    if (params.title) {
      domelem.innerHTML += '<h1>' + params.title + '</h1>';
    }

    domelem.innerHTML += params.contentHtml + '<a class="nnn-icon-right map-popup-arrow"></a>';

    domelem.setAttribute('data-area-id', params.areaId);
    domelem.className = 'clearfix';

    return domelem;
  }

  getPopupOptions() {

    return {
      maxWidth: 305,
      minWidth: 305,
      className: 'area-map-popup',
      closeButton: false
    };
  }

  getCircleMarker(params) {

    const markerOptions = this.getMarkerOptions();

    if (params.inactive) {
      markerOptions.fillOpacity = 0.3;
    }

    const geoJsonObject = L.circleMarker( L.latLng(params.center.latitude, params.center.longitude), markerOptions);
    geoJsonObject.setRadius(params.radius);

    return geoJsonObject;
  }

  getMarkerOptions() {
    return {
      fillColor: '#55AD40',
      fill: true,
      stroke: false,
      fillOpacity: 1,
      className: 'areaCircle'
    };
  }

  getPolygonMarker(geometry, options, popup, popupOptions) {
    const that = this;
    const geoJsonObject = L.geoJSON( geometry, {

      style: feature => {
        return that.getPolygonMarkerOptions(options);
      },

      onEachFeature: (feature, layer) => {
        layer.bindPopup(popup, popupOptions);
      }

    });

    return geoJsonObject;
  }

  /**
   * Get marker options
   *
   * Gets marker options and return it.
   *
   * @params  options         options to merge
   * @return  obj
   */

  getPolygonMarkerOptions(options) {

    const defaultOptions = {
      color: '#FEFE53',
      fill: true,
      fillColor: '#FEFE53',
      fillOpacity: 0.2,
      weight: 3
    };

    return defaultOptions + options;
  }




  getCenter(area: Area): {longitude: number, latitude: number} {
    if (area.geoJSON) {

      const geoJson = area.geoJSON;

      if (geoJson.features && geoJson.features.length !== 0) {

        const features = geoJson.features;
        const firstFeature = features.shift();

        if (firstFeature.geometry && firstFeature.geometry.type && firstFeature.geometry.type === 'Point') {
          if (firstFeature.geometry.coordinates && firstFeature.geometry.coordinates.length !== 0) {

            return {
              longitude: firstFeature.geometry.coordinates[0],
              latitude: firstFeature.geometry.coordinates[1]
            };

          }

        } else if (firstFeature.geometry && firstFeature.geometry.type && firstFeature.geometry.type === 'Polygon') {

          if (firstFeature.geometry.coordinates && firstFeature.geometry.coordinates.length !== 0) {

            const firstGeoArray = firstFeature.geometry.coordinates[0];
            return this.getCenterFromLatLongCoordinates(firstGeoArray);

          }

        } else if (firstFeature.geometry && firstFeature.geometry.type && firstFeature.geometry.type === 'GeometryCollection') {

          if (firstFeature.geometry.geometries && firstFeature.geometry.geometries.length !== 0) {

            const firstGeometry = firstFeature.geometry.geometries[0];

            if (firstGeometry.type && firstGeometry.type === 'MultiPolygon'
                && firstGeometry.coordinates && firstGeometry.coordinates.leading !== 0) {
              const firstGeoArray = firstGeometry.coordinates[0][0];
              return this.getCenterFromLatLongCoordinates(firstGeoArray);
            }

            if (firstGeometry.type && firstGeometry.type === 'Point'
                && firstGeometry.coordinates && firstGeometry.coordinates.length !== 0) {
              return {
                longitude: firstGeometry.coordinates[0],
                latitude: firstGeometry.coordinates[1]
              };
            }

          }

        }
      }
    }
  }

  getCenterFromLatLongCoordinates(geoArray: Array<number>) {

    const longArray: Array<number> = [];
    const latArray: Array<number> = [];

    geoArray.forEach(geo => {
      longArray.push(geo[0]);
      latArray.push(geo[1]);
    });

    const longMin = Math.min.apply(null, longArray); // x1
    const longMax = Math.max.apply(null, longArray); // x2

    const latMin = Math.min.apply(null, latArray); // y1
    const latMax = Math.max.apply(null, latArray); // y2

    return {
      latitude: latMin + ((latMax  - latMin) / 2),
      longitude: longMin + ((longMax - longMin) / 2)
    };
  }

}
