import {Component, OnInit, ViewChild} from '@angular/core';
import {AreaService} from '../../service/AreaService';
import {GeoObject} from '../../model/GeoObject';
import {MenuPageService} from '../../service/MenuPageService';
import {ActivatedRoute, Router} from '@angular/router';
import {AppPageContentPipe} from '../../pipes/AppPageContentPipe';
import {LocaleService} from '../../service/LocaleService';
import {BehaviorSubject} from 'rxjs';
import {MenuStateService} from '../../service/MenuStateService';
import {IonSlides} from '@ionic/angular';
import {POIContent} from '../../model/POIContent';

@Component({
  selector: 'app-poi-details',
  templateUrl: 'poi-details.html',
  styleUrls: ['poi-details.scss'],
})
export class PoiDetailsComponent implements OnInit {

  poi: GeoObject;
  longDescriptionTitle: string;
  longDescription: string;
  loading = true;

  slideOpts: any = {
    mode: 'horizontal',
    loop: true,
    createPagination: true,
    calculateHeight: true
  };

  @ViewChild('slider')
  public slider: IonSlides;

  contents: BehaviorSubject<Array<POIContent>>;

  isAccordion = false;
  accordionGroups: Array<{
    title: string,
    content: Array<{ type: 'audio'|'video'|'image'|'other', value: string, title?: string}>,
    visible: boolean
  }> = [];

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private areaService: AreaService,
      private menuPageService: MenuPageService,
      private appPageContentPipe: AppPageContentPipe,
      private localeService: LocaleService,
      public menuStateService: MenuStateService
  ) {
    this.contents = new BehaviorSubject<Array<POIContent>>([]);
  }

  ngOnInit(): void {

    setTimeout(() => {

      this.route.params.subscribe(params => {
        this.areaService.loaded().subscribe(loaded => {
          if (!loaded) {
            return;
          }

          this.menuPageService.areaDetails(this.areaService.findById(params.areaId));
          this.areaService.findContentForArea(params.areaId).subscribe(content => {
            const poiDescPageMatcher = (poi: {long_desc_page_id}) => poi.long_desc_page_id === Number.parseInt(params.poiId, 0);
            this.poi = Object.values(content.geoobjects).find(poiDescPageMatcher);
            if (!this.poi) {
              this.poi = content.meta.infocenter.pois.find(poiDescPageMatcher);
            }

            if (this.poi) {
              localStorage.setItem('lastPOI', JSON.stringify(this.poi));

              const longDescription = content.findPageById(this.poi.long_desc_page_id);
              this.longDescription = this.appPageContentPipe.transform(
                  longDescription.content,
                  'areas/' + this.localeService.get() + '/' + params.areaId
              );
              this.longDescriptionTitle = this.appPageContentPipe.transform(
                  longDescription.title,
                  'areas/' + this.localeService.get() + '/' + params.areaId
              );

              setTimeout(() => {
                const contents = [];

                const accordion = document.querySelectorAll('.content-wrapper.accordion');
                if (accordion.length > 0) {
                  const accordionItems = document.querySelectorAll('.content-wrapper.accordion .m-item');
                  accordionItems.forEach((item: HTMLLIElement) => {
                    const headerInnerHTML = item.querySelectorAll('.m-header').item(0).innerHTML;
                    const contentAudio = item.querySelectorAll('.m-inner-content').item(0).querySelectorAll('.audio-wrapper');
                    const contentVideo = item.querySelectorAll('.m-inner-content').item(0).querySelectorAll('.video-wrapper');
                    const contentImage = item.querySelectorAll('.m-inner-content').item(0).querySelectorAll('.img-wrapper');

                    const itemContent = [];
                    if (contentAudio.length > 0) {
                      itemContent.push({
                        type: 'audio',
                        value: contentAudio.item(0).getAttribute('data-src'),
                        title: contentAudio.item(0).querySelectorAll('span').item(0).textContent
                      });
                    }
                    if (contentImage.length > 0) {
                      itemContent.push({
                        type: 'image',
                        value: contentImage.item(0).innerHTML
                      });
                    }
                    if (contentVideo.length > 0) {
                      itemContent.push({
                        type: 'video',
                        value: contentVideo.item(0).innerHTML
                      });
                    }

                    this.accordionGroups.push({
                      title: headerInnerHTML,
                      content: itemContent,
                      visible: false
                    });
                  });

                  this.isAccordion = true;
                } else {
                  const elements = document.querySelectorAll('.content-wrapper > div');

                  elements.forEach((element) => {
                    if (element.classList.contains('audio-wrapper')) {
                      const audioPathMatch = element.outerHTML.match(/<div class="audio-wrapper" data-src="([^"]*)"/gi);
                      if (audioPathMatch && audioPathMatch.length > 0) {
                        contents.push(new POIContent({
                          type: 'audio',
                          audioPath: audioPathMatch[0].replace(/<div class="audio-wrapper" data-src="([^"]*)"/gi, '$1'),
                          title: element.querySelectorAll('span')[0].textContent
                        }));
                      }
                    } else if (element.classList.contains('slideshow-container')) {
                      const slides = element.querySelectorAll('.swiper-slide');

                      if (slides.length > 0) {
                        const slideData = [];
                        for (let i = 0, il = slides.length; i < il; ++i) {
                          slideData.push({content: slides.item(i).innerHTML});
                        }
                        contents.push(new POIContent({
                          type: 'slides',
                          slides: slideData
                        }));
                      }
                    } else if (element.classList.contains('img-wrapper')) {
                      contents.push(new POIContent({
                        type: 'image',
                        imageHTML: element.outerHTML
                      }));
                    }
                  });

                  ['slideshow-container', 'audio-wrapper', 'img-wrapper', 'video-wrapper'].forEach(className => {
                    while (document.getElementsByClassName(className).length > 0) {
                      const itm = document.getElementsByClassName(className).item(0);
                      itm.parentElement.removeChild(itm);
                    }
                  });

                  this.contents.next(contents);
                }
              }, 50);

            } else {
              // route to 404 page
              this.router.navigate(['not-found']);
            }

            this.loading = false;
          });
        });
      });

    }, 100);

  }

  toggleGroup(index: number) {
    this.accordionGroups[index].visible = !this.accordionGroups[index].visible;
  }

}
