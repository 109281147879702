import {Component, OnInit} from '@angular/core';
import {LocaleService} from '../../service/LocaleService';
import {MenuStateService} from '../../service/MenuStateService';

@Component({
  selector: 'app-not-found',
  templateUrl: 'not-found.html',
  styleUrls: ['not-found.scss'],
})
export class NotFoundComponent {

  constructor(
      private localeService: LocaleService,
      public menuStateService: MenuStateService
  ) {}
}
