import {Component, OnInit} from '@angular/core';
import {AreaService} from '../../service/AreaService';
import {Area} from '../../model/Area';
import {BehaviorSubject} from 'rxjs';
import {MenuPageService} from '../../service/MenuPageService';
import {AppPageContentPipe} from '../../pipes/AppPageContentPipe';
import {LocaleService} from '../../service/LocaleService';
import {MenuStateService} from '../../service/MenuStateService';

@Component({
  selector: 'app-area-list',
  templateUrl: 'area-list.html',
  styleUrls: ['area-list.scss'],
})
export class AreaListComponent implements OnInit {

  areas: BehaviorSubject<Array<{idareas: number, title: string, img: string}>>;

  constructor(
      private areaService: AreaService,
      private menuPageService: MenuPageService,
      private appPageContentPipe: AppPageContentPipe,
      private localeService: LocaleService,
      public menuStateService: MenuStateService
  ) {
    this.areas = new BehaviorSubject<Array<{idareas: number, title: string, img: string}>>(null);
  }

  ngOnInit() {

    this.menuPageService.reset();
    setTimeout(() => {
      this.areaService.loaded().subscribe(loaded => {
        if (!loaded) {
          return;
        }
        this.areas.next(this.areaService.findAll().map(a => {
          const page = this.areaService.findPageById(a.short_desc_page_id);
          const regExp = new RegExp('<img src="([\\w\\d\\{\\}_\\.\\/]+)".*\/>');

          const imgTag = page.content.match(regExp);
          let img;
          if (imgTag.length) {
            img = imgTag[0].replace(regExp, '$1');
          }
          return {
            idareas: a.idareas,
            title: a.title,
            img: this.appPageContentPipe.transform(img, 'app/' + this.localeService.get())
          };
        }));
      });
    }, 500);
  }

  getAreaListItemTitle(area: Area) {
    const page = this.areaService.findPageById(area.short_desc_page_id);
    return page.title;
  }
}
