import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {AreaMapComponent} from './components/area-map/area-map.component';
import {AreaListComponent} from './components/area-list/area-list.component';
import {AreaService} from './service/AreaService';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {LocaleService} from './service/LocaleService';

import {PoiMapComponent} from './components/poi-map/poi-map.component';
import {PoiListComponent} from './components/poi-list/poi-list.component';
import {PoiDetailsComponent} from './components/poi-details/poi-details.component';
import {AreaDetailsComponent} from './components/area-details/area-details.component';
import {AppPageContentPipe} from './pipes/AppPageContentPipe';
import {MenuPageService} from './service/MenuPageService';
import {VgCoreModule} from 'videogular2/core';
import {VgControlsModule} from 'videogular2/controls';
import {VgOverlayPlayModule} from 'videogular2/overlay-play';
import {VgBufferingModule} from 'videogular2/buffering';
import {NotFoundComponent} from './components/not-found.component/not-found.component';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MenuStateService} from './service/MenuStateService';
import {SafeHTMLPipe} from './pipes/SafeHTMLPipe';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        AreaMapComponent,
        AreaListComponent,
        AreaDetailsComponent,
        PoiMapComponent,
        PoiListComponent,
        PoiDetailsComponent,
        AppPageContentPipe,
        SafeHTMLPipe,
        NotFoundComponent
    ],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        StatusBar,
        SplashScreen,
        AreaService,
        LocaleService,
        AppPageContentPipe,
        MenuPageService,
        MenuStateService,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
